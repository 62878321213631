<template>
  <div id="sns">
     <vue-yandex-share v-bind="options"></vue-yandex-share>
  </div>
</template>
 
<script>
import VueYandexShare from '@alexlit/vue-yandex-share';
 
export default {
  name: "App",
  components: { 
    VueYandexShare
  },
  data()  {
    return {
      options: {
        accessToken: null,
        bare: false,
        counter: false,
        copy: 'last',
        description: null,
        direction: 'horizontal',
        hashtags: '',
        image: null,
        lang: 'ru',
        limit: 24,
        popupDirection: 'bottom',
        popupPosition: 'inner',
        size: 'm',
        title: null,
        url: null,
        services: [
          'facebook',
          'pinterest',
          'pocket',
          'reddit',
          'skype',
          'twitter',
        ],
      },
    };
  },
};
</script>
<style>
#sns {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 35px;
  margin: 0 auto;
  width: 400px;
}
 
</style>