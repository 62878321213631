<template>

    <div class="card-body">
        <p class="card-text">
            ゲーム（PC）の振り向き計算ツールです。<br/>
            ゲームを選択後、DPIと感度に値を入力すると自動計算されます。(cm=180)<br/>
            感度には選択したゲームのゲーム内感度を設定してください。<br/>
            <br/>
            <b>対応ゲーム一覧</b><br/>
            SUPER PEOPLE<br/>
            Apex Legends,Fortnite,CS:GO,OVERWATCH,Back 4 Blood<br/>
            CoD MW,CoD: BO CW,CoD: Vanguard,Escape from Tarkov<br/>
            VALORANT,Rainbow Six Siege,Hyper Scape,Halo Infinite<br/>
            Battlefield 2042,Battlefield V,PUBG,Krunker.io<br/>
            AVA,Destiny 2,Ironsight,osu!,ガンダムオンライン<br/>
            <br/>
            ログインすると計算結果を登録でき、計算結果を一覧で保持、共有することができます。<br/>
            登録したデータは振り向き統計に反映されます。<br/>
            <br/>

            バグ等ありましたら「問い合わせ」から報告お願いいたします。<br/>
            <br/>
            <br/>

        </p>
        <div class="form-group">
            <label class="control-label">タイトル</label>
            <select class="form-control col-xs-4 col-md-4" name="gameTitle" id="gameTitle" v-model="gameTitle">
                <option v-for="item in game_titles" v-bind:value="item.game" v-bind:key="item.game_jp">{{ item.game_jp }}</option>
            </select>
        </div>
        <br/>
        <div class="form-group">
            <label>DPI
                <input name="dpi" v-model="dpi" type="number" step="1" class="form-control"/>
            </label>
            <br/>
            <label>感度
                <input name="sensitivity" v-model="sensitivity" type="number" step="0.001" class="form-control" />
            </label>
            <br/>
            <label v-if="gameTitle=='pubg_fpp' || gameTitle=='pubg_tpp' || gameTitle=='super_people'">FOV
                <input name="fov" v-model="fov" type="number" class="form-control"/>
                <br/>
            </label>
            <div v-if="gameTitle=='osu' || gameTitle=='gundum' || gameTitle=='krunker'" >
                <label class="control-label">WPS</label>
                <select name="wps" class="form-control col-xs-3 col-md-3" v-model="wps">
                    <option value="0.03125">0(1/32)</option>
                    <option value="0.0625">1(1/16)</option>
                    <option value="0.25">2(2/8)</option>
                    <option value="0.5">3(4/8)</option>
                    <option value="0.75">4(6/8)</option>
                    <option value="1" selected>5(1.0)</option>
                    <option value="1.5">6(1.5)</option>
                    <option value="2">7(2.0)</option>
                    <option value="2.5">8(2.5)</option>
                    <option value="3">9(3.0)</option>
                    <option value="3.5">10(3.5)</option>
                </select><br/>
            </div>
            <label v-if="gameTitle=='osu'">解像度
            <input name="resolution" v-model="resolution" type="number" class="form-control"/><br/></label>
            <br/>
            <label v-if="gameTitle=='r6s' || gameTitle=='valo'">エイム感度
            <input name="ads_sensi" v-model="ads_sensi" type="number" step="0.001" class="form-control"/><br/></label>
            <br/>
            <label v-if="gameTitle=='r6s'">MouseSensitivityMultiplierUnit
            <input name="multiplier_unit" v-model="multiplier_unit" type="number" step="0.000001" class="form-control"/><br/></label>
            <br/>
            <label v-if="gameTitle=='r6s'">XfactorAiming
            <input name="x_factor_aiming" v-model="x_factor_aiming" type="number" step="0.000001" class="form-control"/><br/></label>
            <br/>
        </div>
        <div id="output" class="card-footer">
        <h5>計算結果(cm=180)</h5>
            <label class="col-md-3 col-xs-3">振り向き(Hipfire)
                <input name="cmp180" type="text" v-bind:value="cmp180.toFixed(2)" readonly class="form-control"/>
            </label>
            <!-- r6s -->
            <label class="col-md-3 col-xs-3" v-if="gameTitle=='r6s'">ADS
                <input name="ads180" type="text" v-bind:value="ads180.toFixed(2)" readonly class="form-control"/>
            </label>
            <label class="col-md-3 col-xs-3" v-if="gameTitle=='r6s'">ACOG
                <input name="acog180" type="text" v-bind:value="acog180.toFixed(2)" readonly class="form-control"/>
            </label>
            <!-- valorant -->
            <label class="col-md-3 col-xs-3" v-if="gameTitle=='valo'">×1.15(spectre,stinger)
                <input name="ads115" type="text" v-bind:value="ads115.toFixed(2)" readonly class="form-control"/>
            </label>
            <label class="col-md-3 col-xs-3" v-if="gameTitle=='valo'">×1.25(odin,bulldog,phantom etc..)
                <input name="ads125" type="text" v-bind:value="ads125.toFixed(2)" readonly class="form-control"/>
            </label>
            <label class="col-md-3 col-xs-3" v-if="gameTitle=='valo'">×1.50(guardian)
                <input name="ads150" type="text" v-bind:value="ads150.toFixed(2)" readonly class="form-control"/>
            </label>
            <label class="col-md-3 col-xs-3" v-if="gameTitle=='valo'">×2.50(operator,marshal)
                <input name="ads250" type="text" v-bind:value="ads250.toFixed(2)" readonly class="form-control"/>
            </label>
            <label class="col-md-3 col-xs-3" v-if="gameTitle=='valo'">×5.00(operator)
                <input name="ads500" type="text" v-bind:value="ads500.toFixed(2)" readonly class="form-control"/>
            </label>

            <br/>
            <br/>
            <div class="twitter_share">
                <button type="button" class="tweet" @click="makeTweet"><i class="fab fa-twitter"></i>&nbsp; 結果をツイート</button>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: {
        game_titles:{
            type: Array,
            required: true,
        }
    },
    name: "SensiCalc",
    data:function () {
        return {
            dpi:400,
            sensitivity:10,
            fov:103,
            number: 0,
            gameTitle:'apex',
            wps:1,
            resolution:1920,
            multiplier_unit: 0.02,
            x_factor_aiming: 0.02,
            ads_sensi: 50
        }
    },
    computed: {
        cmp180 : function () {
            if(this.gameTitle == 'ow' || this.gameTitle == 'mw' || this.gameTitle == 'mw2' || this.gameTitle == 'bo4' || this.gameTitle == 'cw' || this.gameTitle == 'vanguard'){
                return (360 / (this.dpi * this.sensitivity * 0.0066) * 2.54)/2
            }else if(this.gameTitle == 'csgo' || this.gameTitle == 'apex'){
                return (360 / (this.dpi * this.sensitivity * 0.0066) / 2 * 3 / 5 * 2.54)/2
            }else if(this.gameTitle == 'fortnite'){
                return (360 / (this.dpi * this.sensitivity * 0.00555500005555) * 2.54)/2
            }else if(this.gameTitle == 'bfv'){
                return (360  / (this.dpi * ((5/32)*(Math.pow(5, 0.5) * Math.pow(6400 * this.sensitivity + 51061373, 0.5) - 15975)) * 0.022) * 2.54)/2
            }else if(this.gameTitle == 'r6s'){
                return (360 / this.dpi * 8.866272600131111) * (1 / (this.multiplier_unit * this.sensitivity)) / 2
            }else if(this.gameTitle == 'pubg_tpp'){
                return (360 / (this.dpi * 2.22222 * (0.0016*Math.pow(10,this.sensitivity/ 50) * this.fov  / 80)) * 2.54)/2
            }else if(this.gameTitle == 'pubg_fpp'){
                return (360 / (this.dpi * 2.22222 * (0.00203*Math.pow(10,this.sensitivity/ 50) * this.fov  / 80)) * 2.54)/2
            }else if(this.gameTitle == 'super_people'){
                return (360 / (this.dpi * 2.22222 * (0.0026213*Math.pow(10,this.sensitivity/ 50) * this.fov  / 80)) * 2.54)/2
            }else if(this.gameTitle == 'ironsight'){
                return (360 / (this.dpi * this.sensitivity * 0.01) * 2.54)/2
            }else if(this.gameTitle == 'osu'){
                return (this.resolution / (this.dpi * this.sensitivity * this.wps) *2.54)
            }else if(this.gameTitle == 'gundum'){
                return (48768 / (this.sensitivity + 1) / this.wps / this.dpi)/2
            }else if(this.gameTitle == 'valo'){
                this.ads_sensi = 1
                return (360 / (this.dpi * this.sensitivity * 0.0175) * 2.54)/4/2
            }else if(this.gameTitle == 'krunker'){
                return (360 / (this.dpi * this.sensitivity * this.wps * 0.137544) * 2.54)/2
            }else if(this.gameTitle == 'destiny2'){
                return (360 / (this.dpi * this.sensitivity * 0.0066) * 2.54)/2
            }else if(this.gameTitle == 'ava'){
                return (360 / (this.dpi * this.sensitivity * 0.0045) * 2.54)/2
            }else if(this.gameTitle == 'hyperscape'){
                return (360 / (this.dpi * this.sensitivity * 0.0057295) * 2.54)/2
            }else if(this.gameTitle == 'rust'){
                return (360 / (this.dpi * this.sensitivity * 0.1125) * 2.54)/2
            }else if(this.gameTitle == 'paladins'){
                return (360 / (this.dpi * this.sensitivity * 0.00916) * 2.54)/2
            }else if(this.gameTitle == 'bf2042'){
                return (360  / (this.dpi * ((5/32)*(Math.pow(5, 0.5) * Math.pow(6400 * this.sensitivity + 51061373, 0.5) - 15975)) * 0.0146) * 2.54)/2
            }else if(this.gameTitle == 'b4b'){
                return (360 / (this.dpi * this.sensitivity * 0.0023331) * 2.54)/2
            }else if(this.gameTitle == 'tarkov'){
                return (360 / (this.dpi * this.sensitivity * 0.125) * 2.54)/2
            }else if(this.gameTitle == 'halo_infinite'){
                return (360 / (this.dpi * this.sensitivity * 0.0225) * 2.54)/2
            }else{
                return 0
            }
        },
        ads180 : function () {
            if(this.gameTitle == 'r6s'){
                return this.cmp180 * (1 / Math.min(this.ads_sensi * this.x_factor_aiming * 0.6, 1) )
            }else{
                return 0
            }
        },
        acog180 : function () {
            if(this.gameTitle == 'r6s'){
                return this.cmp180 * (1 / Math.min(this.ads_sensi * this.x_factor_aiming * 0.35, 1) )
            }else{
                return 0
            }
        },
        ads115 : function () {
            if(this.gameTitle == 'valo'){
                return this.cmp180 * 1.15 / this.ads_sensi
            }else{
                return 0
            }
        },
        ads125 : function () {
            if(this.gameTitle == 'valo'){
                return this.cmp180 * 1.25 / this.ads_sensi
            }else{
                return 0
            }
        },
        ads150 : function () {
            if(this.gameTitle == 'valo'){
                return this.cmp180 * 1.5 / this.ads_sensi
            }else{
                return 0
            }
        },
        ads250 : function () {
            if(this.gameTitle == 'valo'){
                return this.cmp180 * 2.5 / this.ads_sensi
            }else{
                return 0
            }
        },
        ads500 : function () {
            if(this.gameTitle == 'valo'){
                return this.cmp180 * 5 / this.ads_sensi
            }else{
                return 0
            }
        }

    },
    methods: {
        makeTweet : function(){
        var gameName = $("#gameTitle").children("option:selected").text()
        var shareURL = 'https://twitter.com/intent/tweet?text=' + "私の"+ gameName + "の振り向きは " 
        + this.cmp180.toFixed(2) + "cmです。(DPI:" + this.dpi + " 感度:" + this.sensitivity + ") %23GSettings" + " %23振り向き計算ツール" + '&url=' + "https://www.sensi9.com/sensi";;
        location.href = shareURL
        }
    }
}

</script>

<style scoped>
.tweet {
    background-color: #55acee;
    border: 2px solid #55acee;
    border-radius: 0;
    color: #fff;
    padding:4px 32px;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.tweet:hover {
    background-color: #fff;
    color: #55acee;
}</style>