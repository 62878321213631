
<script>  
    import { Bar } from 'vue-chartjs'  

    export default {  
        props: {
            chart_label:{
                type: Array,
                required: false,
            },
            chart_data:{
                type:Array,
                required:false,
            }
        },
        extends: Bar,  
        data: () => ({  
            chartdata: {  
            labels: [],  
            datasets: [  
                {  
                label: "振り向き別人数",  
                backgroundColor: '#f87979',  
                data: []  
                }  
            ]  
            },  
            options: {  
            responsive: true,  
            maintainAspectRatio: false  
            }  
        }),  
        mounted () {  
            this.renderChart(this.chartdata, this.options)  
        },
        methods:{
            getLabel(){
                return this.chartdata.labels = this.chart_label;
            },
            getData(){
                return this.chartdata.datasets[0].data = this.chart_data;
            },
        },
        created:function(){
            this.getLabel();
            this.getData();
        }

    }  
</script>