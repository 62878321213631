<template>
    <div class="container">

        <div class="card mb-8 text-left" >
            <div v-if="mouse_sensis.length != 0">
                <div class="card mb-8 shadow-sm" v-for="(mouse_sensi,id) of mouse_sensis" :key="id">          
                    <div class="card-header">
                        <strong>{{ mouse_sensi.game }}</strong>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">
                            <small class="text-muted">DPI:</small>{{ mouse_sensi.dpi }}
                            <small class="text-muted">  ゲーム内感度:</small>{{ mouse_sensi.sensi }}
                            <small class="text-muted" v-if="mouse_sensi.game=='PUBG(TPP)' || mouse_sensi.game=='PUBG(FPP)' || mouse_sensi.game=='SUPER PEOPLE' ">  FOV:</small>{{ mouse_sensi.fov }}
                            <small class="text-muted" v-if="mouse_sensi.game=='osu!' || mouse_sensi.game=='ガンダムオンライン'">  WPS:</small>{{ mouse_sensi.wps }}
                            <small class="text-muted" v-if="mouse_sensi.game=='osu!'">  解像度:</small>{{ mouse_sensi.resolution }}
                        </h5>
                        <h5 class="card-title" v-if="mouse_sensi.game!='osu!'">
                            <small class="text-muted">振り向き:</small>{{ mouse_sensi.cmp180 }}<br/>
                        </h5>
                        <h5 class="card-title" v-if="mouse_sensi.game=='osu!'">
                            <small class="text-muted">振り向き(端から端まで移動したときの距離):</small>{{ mouse_sensi.cmp180 }}<br/>
                        </h5>
                    </div>
                </div>
                <br/><br/>
                <div class="twitter_share">
                    <button type="button" class="tweet" @click="makeTweet"><i class="fab fa-twitter"></i>&nbsp; ツイートで共有</button>
                </div>
            </div>
            <div v-else>
                <br/>
                設定が登録されていません。<br/>
                <br/>
                メニュー「振り向き計算」から設定を登録してください。<br/>
                <br/>
            </div>
        </div>
        <br/>
    </div>

</template>

<script>
export default {
    props: {
        mouse_sensis:{
            type: Array,
            required: false,
        },
        user_id:{
            type: Number,
            required: false,
        },
    },
    methods: {
        makeTweet : function(){
        var path = 'https://www.sensi9.com'
        var shareURL = 'https://twitter.com/intent/tweet?text=' + "私の設定一覧"  + " %23GSettings" + " %23振り向き計算ツール" + '&url=' + path + "/gsettings?id=" + this.user_id;
        location.href = shareURL
        }
    }
}
</script>

<style scoped>
.tweet {
    background-color: #55acee;
    border: 2px solid #55acee;
    border-radius: 0;
    color: #fff;
    padding:4px 32px;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.tweet:hover {
    background-color: #fff;
    color: #55acee;
}</style>